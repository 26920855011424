// Styles for each module. Should move to
// 'main app styles' if some class is shared by two or more modules.

/***** MAIN APP STYLES *****/

// new colors of itx guide - provided by Brian Loughner
$colors-blue-50: #e9f4ff;
$colors-blue-100: #b9dbff;
$colors-blue-200: #72acea;
$colors-blue-300: #1978d0;
$colors-blue-400: #2a66d0;
$colors-blue-500: #184fb0;
$colors-blue-600: #055195;
$colors-blue-700: #003a6f;
$colors-green-50: #f4fff9;
$colors-green-100: #dcf7e8;
$colors-green-200: #b5ebca;
$colors-green-300: #91d19f;
$colors-green-400: #3ca655;
$colors-green-500: #267d30;
$colors-green-600: #176621;
$colors-green-700: #024b0a;
$colors-itx-green-200: #d1e0bc;
$colors-itx-green-400: #a4b74a;
$colors-itx-green-500: #7f8f11;
$colors-itx-green-600: #637015;
$colors-itx-green-700: #424b1a;
$colors-itx-orange-200: #f9d39c;
$colors-itx-orange-500: #ffba4c;
$colors-itx-orange-700: #8b4513;
$colors-itx-purple-200: #e7d9e4;
$colors-itx-purple-500: #87629f;
$colors-itx-purple-700: #645079;
$colors-itx-red-200: #f1d8d5;
$colors-itx-red-500: #c9413e;
$colors-itx-red-700: #9e3226;
$colors-itx-teal-200: #d0e1eb;
$colors-itx-teal-500: #007588;
$colors-itx-teal-700: #245969;
$colors-neutrals-ink: #2f2d31;
$colors-neutrals-n100: #f9fafb;
$colors-neutrals-n200: #ecf1f6;
$colors-neutrals-n300: #e2e7ee;
$colors-neutrals-n400: #d5d8e4;
$colors-neutrals-n500: #c8cbd3;
$colors-neutrals-n600: #afb2c6;
$colors-neutrals-n700: #8b90aa;
$colors-neutrals-n800: #707593;
$colors-neutrals-n900: #3c4260;
$colors-neutrals-p500: #464ccf;
$colors-neutrals-white: #ffffff;
$colors-neutrals-black: #000000;
$colors-orange-50: #fff5df;
$colors-orange-100: #ffe3a2;
$colors-orange-200: #ffd27a;
$colors-orange-300: #ffb12c;
$colors-orange-400: #db7e05;
$colors-orange-500: #ae6505;
$colors-orange-600: #7c530b;
$colors-orange-700: #573800;
$colors-purple-50: #faf9ff;
$colors-purple-100: #eeecf5;
$colors-purple-200: #cbc7e1;
$colors-purple-300: #9c95c0;
$colors-purple-400: #6357bb;
$colors-purple-500: #464ccf;
$colors-purple-600: #52459a;
$colors-purple-700: #25167a;
$colors-red-50: #fff1f1;
$colors-red-100: #fcd6d6;
$colors-red-200: #ef9999;
$colors-red-300: #d73f3b;
$colors-red-400: #c0221e;
$colors-red-500: #a81a1b;
$colors-red-600: #8b1213;
$colors-red-700: #74090a;

// common spacing
$spacing-spacing-4: 4px;

// palette
$primary: $colors-purple-400;
$primary-light: #d1ccce;
$primary-background: #dcdaeb;
$light: #f4f4f4;
$dark: #cccccc;
$gray-200: #e4e4e4;
$red: red;

// fonts - color
$font-black: $colors-neutrals-black;
$criteria-bold: #6b6a6a;
$help-text: #868686;

$red-button: #d2583e;
$criteria-achieved: #e3e3e3;
$criteria-unachieved: #f5f5f5;
$criteria-hover: #dcdaeb;

$greenIcon: #9aa63e;
$backgroundGreenIcon: #f5f6ec;
$greenIcon30: #e1e4c5;
$greenIcon50: #c0c692;

$yellowIcon: #ffeb33;
$backgroundYellowIcon: #fffbda;
$yellowIcon30: #fff9c2;
$yellowIcon50: #f3e88c;

$orangeIcon: #f7a23c;
$backgroundOrangeIcon: #feeedc;
$orangeIcon30: #fde3c5;
$orangeIcon50: #efc491;

$redIcon: #cc3419;
$backgroundRedIcon: #faebe8;
$redIcon30: #f0c2ba;
$redIcon50: #d98d7f;

$blueIcon: #4a78c1;
$backgroundBlueIcon: #edf1f9;
$blueIcon30: #c9d7ec;
$blueIcon50: #98afd3;

// select styles are on Select.jsx file
$select-border-color: $colors-neutrals-n700;
$select-border-hover: $colors-purple-400;
$select-background-active: $colors-purple-100;
$select-text: $colors-neutrals-ink;
$select-arrow-color: $colors-neutrals-n800;

$iconColor: #717171;
$info-icon: rgba(82, 69, 154, 0.6);
$disc-icon: #978fc2;
$trust: $colors-itx-teal-500;
$loyal: $colors-itx-green-400;
$advocate: $colors-itx-purple-500;
$below: $colors-itx-red-500;
$driver: $colors-itx-orange-500;
$initialsHover: #25242999;
$sortBubble: #fcdfbb;
$filterColor: #707171;
$backgroundProfile: #e5e3f0;
$border-input: #ced4da;

// general type
$body-color: $colors-neutrals-black;
$btn-font-size: 13px;
$background-white: $colors-neutrals-white;
$grayMedium: #9ea0a5;
$table-border: #eaedf3;
$table-border-top: #eaecee;
$darkMedium: #3e3e3e;
$darkSoft: #474747;
$role-gray: #636363;
$arrowGray: #979797;
$selectedBg: #f3f3f3;
$criteria: #fff9ee;
$box-shadow: #ececec;
$border-block: #cacaca;

// EXPORT COLORS
:export {
  colorBadgeApproved: $colors-itx-green-400;
  colorBadgePending: $colors-orange-300;
  colorBadgeExported: $colors-itx-teal-500;
  colorBadgeReset: $colors-neutrals-n800;
  colorBadgeVoid: $colors-neutrals-n400;
}

// HEADER
.header {
  position: fixed;
  top: 0;
  z-index: 70;
  height: 60px;
  width: 100%;
  background-color: $colors-neutrals-white;
  border-bottom: 1px solid $colors-neutrals-n400;

  .header-logo {
    margin: 16px 0 20px 30px;
  }
  .header-logo-env {
    width: 70px;
    margin: 16px 0 20px 5px;
  }

  .header-link {
    color: $colors-neutrals-ink;
    padding: 8px 12px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    border: 1px solid $colors-neutrals-white;
    cursor: pointer;
    width: max-content;
    &:hover {
      color: $colors-purple-600;
      border: 1px solid $colors-purple-600;
      border-radius: $spacing-spacing-4;
      background-color: $colors-neutrals-n200;
    }
  }

  .header-icon {
    border-radius: $spacing-spacing-4;
    background: $colors-neutrals-n200;
    margin: 14px 0;

    .icon {
      width: 20px;
      height: 20px;
      margin: 6px;
      color: $colors-neutrals-n800;
      &:hover {
        color: $colors-purple-400;
        cursor: pointer;
      }
    }
  }

  .header-title-hours {
    color: $colors-neutrals-ink;
    font-size: 11px;
    font-weight: 600;
    margin-top: 13px;
    &:hover {
      color: $colors-purple-400;
      cursor: pointer;
    }
  }
  .header-hours {
    color: $colors-neutrals-n800;
    font-size: 12px;
    font-weight: 400;
    .link-hours {
      color: $colors-neutrals-n800;
      text-decoration: none;
      cursor: pointer;
    }
  }
  .user-container {
    .user-image {
      border-radius: 50%;
      border: 2px solid $colors-neutrals-n500;
      margin: 12px 0 13px 0;
      width: 35px;
      height: 35px;
      transition: border 0.4s;
    }
    .user-initials {
      display: inline-flex;
      border-radius: 50%;
      border: 2px solid $colors-neutrals-n500;
      margin: 12px 0 13px 0;
      padding: 5px 0 0 4px;
      width: 35px;
      height: 35px;
      font-size: 13px;
      transition: border 0.4s;
      color: $colors-neutrals-white;
      background-color: $colors-itx-teal-500;
      &.grey {
        background-color: $colors-neutrals-n200;
      }
    }
    .user-arrow {
      width: 14px;
      height: 13px;
      flex-shrink: 0;
      color: $colors-neutrals-n800;
    }
    &:hover {
      .user-image,
      .user-initials {
        border: 2px solid $colors-purple-400;
        cursor: pointer;
      }
      .user-arrow {
        cursor: pointer;
      }
    }
  }
  .user-modal {
    position: fixed;
    left: calc(100vw - 255px);
    top: 65px;
    width: 220px;
    padding: 5px 0 5px 0;
    max-height: 60vh;
    overflow-y: auto;

    hr {
      margin: 0;
    }

    .user-modal-no-links {
      background-color: $colors-purple-50;
      color: $colors-neutrals-n900;
      font-size: 12px;
      font-weight: 400;
      padding: 21px 0 21px 44px;
    }

    .user-modal-link {
      padding: 14px 20px;
      color: $colors-neutrals-ink;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.back-purple {
        background-color: $colors-purple-50;
        padding: 12px 15px 12px 35px;
        cursor: pointer;
      }

      .user-modal-link-purple {
        color: $colors-purple-400;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .user-log-out {
      color: $colors-red-400;
      cursor: pointer;

      .icon-log-out {
        width: 20px;
        height: 20px;
      }
    }
  }
  .tertiary-button {
    color: $colors-purple-400;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 16px;
    margin: auto 30px;
  }
}

// Main content without header
.main-content {
  top: 60px;
  position: relative;
  padding-top: 1rem;
}

// Header module
.header-separator {
  margin: 0px -12.5% 0 -13%;
}
@media (max-width: 1400px) {
  .header-separator {
    margin: 0px -5.5% 0 -13%;
  }
}

.back-button-container {
  margin: 0 0 16px -27px;
  color: $colors-neutrals-p500;
  display: table;
  cursor: pointer;
}
.back-button-arrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin: -3px 8px 0 0;
}
.back-button-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.header-module-title {
  color: $colors-neutrals-black;
  font-size: 22px;
  font-weight: 500;
  line-height: 21px;
}
.header-module-link {
  color: $colors-neutrals-ink;
  padding: 8px 12px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border: 1px solid $colors-neutrals-white;
  cursor: pointer;
  &:hover {
    color: $colors-purple-400;
    border: 1px solid $colors-purple-400;
    border-radius: $spacing-spacing-4;
  }
}

// Footer
.footer {
  color: $colors-neutrals-n800;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: auto;
}
.footer-report {
  bottom: 0%;
  position: absolute;
  width: 85%;
}

// forms
$form-check-input-width: 1.25em;

// layout
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

// table and summary
$invoices-border: #d5d8e4;

// separator
$separator: #8f93b0;

// Includes bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "Noto Sans";
  src: url(./../fonts/NotoSans-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Noto Sans";
  src: url(./../fonts/NotoSans-Medium.ttf) format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Noto Sans";
  src: url(./../fonts/NotoSans-SemiBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Noto Sans";
  src: url(./../fonts/NotoSans-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: 700;
}

// Styles that are common to the entire application
* {
  font-family: Noto Sans, Geneva, Tahoma, sans-serif;
}

body {
  font-size: 14px;
}

h1 {
  font-size: 26px;
}
h2 {
  font-size: 22px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
  font-weight: 500;
}
h6 {
  font-size: 14px;
  font-weight: 400;
}

// set actual bootstrap btn minus border-radius
.btn {
  border-radius: 4px;
  min-width: 100px;
  height: 36px;
}

.pointer {
  cursor: pointer;
}

// font size
.fw {
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
  &-700 {
    font-weight: 700;
  }
}

// Custom width
.w {
  &-40 {
    width: 40% !important;
  }
  &-70 {
    width: 70% !important;
  }
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.column {
  &-55 {
    width: 55%;
  }
  &-40 {
    width: 40%;
  }
  &-36 {
    width: 36%;
  }
  &-30 {
    width: 30%;
  }
  &-25 {
    width: 25%;
  }
  &-20 {
    width: 20%;
  }
  &-16 {
    width: 16%;
  }
  &-15 {
    width: 15%;
  }
  &-10 {
    width: 10%;
  }
  &-8 {
    width: 8%;
  }
  &-6 {
    width: 6%;
  }
  &-5 {
    width: 5%;
  }
}

.bc-white {
  background-color: $background-white;
}

.toUppercase {
  text-transform: uppercase;
}
.toCapitalize {
  text-transform: capitalize;
}

.text-decoration-none {
  text-decoration: none;
}

/***** BILLING MODULE STYLES *****/

.sticky {
  position: sticky;
  top: 0;

  &-tabs {
    z-index: 50;
  }
  &-date {
    top: 58px;
    z-index: 50;
    height: 42px;
    background-color: $colors-neutrals-white;
  }

  &-header {
    top: 58px;
    height: 38.5px;
    z-index: 50;
    background-color: $colors-neutrals-n100;
    &-with-date {
      top: 95px;
      height: 38.5px;
      z-index: 50;
      background-color: $colors-neutrals-n100;
    }
  }

  &-with-tabs {
    position: sticky;
    top: 100px;
    z-index: 30;
    background-color: $colors-neutrals-white;
  }

  &-with-project {
    position: sticky;
    top: 140px;
    z-index: 20;
    background-color: $colors-neutrals-white;
  }
}

.sorted-column::after {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.sorted-asc::after {
  content: "";
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #4d4d4d;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.sorted-desc::after {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 5px solid #4d4d4d;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.form-check-input:disabled {
  background: lightgray;
}

.invoice-scroll-link {
  text-decoration: none;
}

.invoice-navigate {
  border: 1px solid $arrowGray;
  border-radius: 3px;

  &-left {
    width: 34px;
    height: 34px;
    border-radius: 0;
    &:hover {
      background: #d8d8d8;
    }
  }
  &-right {
    border-radius: 0;
    border-left: 1px solid $arrowGray;
    width: 34px;
    height: 34px;
    &:hover {
      background: #d8d8d8;
    }
  }
}

.footer-filter {
  width: 66%;
  display: flex;
  margin-left: 17%;
  justify-content: space-between;
  &.search-id {
    width: 40.5%;
    margin-left: 29.5%;
  }
}
.separator {
  width: 65.5%;
  margin: 25px auto 0 auto !important;
  color: $separator;
  &.search-id {
    width: 40.5%;
    margin: 25px auto 0 auto !important;
  }
}
.separator-actions {
  width: 100%;
  margin: 5px auto !important;
  color: $font-black;
}

// select class autogenerated
[class$="-multiValue"] {
  border-radius: 125px !important;
  background-color: #ecf1f6 !important;
  border: none !important;
  color: $select-text !important;

  [class$="-Svg"] {
    width: 20px !important;
    height: 20px !important;
    margin-right: 2px !important;
  }
}
[class$="-control"] {
  box-shadow: none !important;
  min-height: 36px;
}
[class$="-control"]:active {
  border: 1px solid $select-border-hover;
  background-color: $select-background-active;
}
[class$="-indicatorContainer"] {
  color: $select-arrow-color !important;
}
[class$="-ValueContainer"] {
  padding: 3px 10px 6px !important;
}

input[type="text"],
.MuiInputBase-input {
  border: 1px solid $select-border-color;
  color: $select-text;
  &:hover {
    border-color: transparent;
    box-shadow: 0 0 0 2px $select-border-hover !important;
    border-radius: 4px;
  }
  &.disabled:hover {
    box-shadow: none !important;
    border: 1px solid $select-border-color;
  }
  &.Mui-disabled:hover {
    box-shadow: none !important;
    background-color: #f2f2f2;
  }
  &:active {
    border-color: transparent;
    box-shadow: 0 0 0 2px $select-border-hover !important;
    background-color: $select-background-active;
  }
  &.disabled:active {
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none;
  }
}

.invoice-summary-background {
  background-color: $colors-neutrals-n100;
  border: 1px solid $invoices-border;
}

// COLORS TABLE
.table {
  &.grey-table-header-background tr th {
    background-color: $colors-neutrals-n100;
    color: $colors-neutrals-ink;
  }
  // time confirmation
  &.grey-table-header-background.time-confirmation tr th {
    background-color: $colors-neutrals-n200;
    color: $colors-neutrals-ink;
  }

  &.grey-table-header-background tr td {
    background-color: $colors-neutrals-white;
    color: $colors-neutrals-ink;
  }
  &.white-table-header-background tr th {
    background-color: $colors-neutrals-white;
    color: $colors-neutrals-ink;
  }
  &.white-table-header-background tr td {
    background-color: $colors-neutrals-n100;
    color: $colors-neutrals-ink;
  }
}

.even-row > td {
  background-color: $colors-neutrals-white !important;
  border-width: 1px !important;
}
.odd-row > td {
  background-color: $colors-neutrals-n100 !important;
  border-width: 1px !important;
}

[class^="Tab_tab__"],
div[class*=" Tab_tab__"] {
  display: flex;
  justify-content: flex-start;
  width: 220px;
  background-color: $colors-neutrals-white !important;
  border-left: none !important;

  &.active {
    border-bottom: 3px solid $colors-purple-500;
    .header-link {
      font-weight: 600;
    }
  }
  &.active .tab-title {
    color: $primary;
  }
}
.top-nav-tab {
  width: auto !important;
  padding: 0 !important;
  margin-right: 16px;
  border-bottom: 3px solid transparent;
  flex-direction: column;
  justify-content: center !important;
  color: $colors-neutrals-ink;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  &.active {
    margin-right: 15px;
    min-height: 59px;
  }
}

// sheet view table borders
.sheet-view-border {
  border: 1px solid #dee2e6;
  &.no-left {
    border-left: none;
  }
  &.no-right {
    border-right: none;
  }
  &.no-sides {
    border-left: none;
    border-right: none;
  }
}

.totals-border {
  border: 1px solid $colors-neutrals-n600;
}

.show-on-hover {
  display: none;
}

.comment-line:hover {
  .show-on-hover {
    display: flex;
  }
}

.dropdown-menu {
  position: absolute;
  top: auto;
  left: 0;
  transform: translate(0, 2px);

  &.right {
    right: 0px;
    left: auto;
  }
}

.form-select-sm,
.form-control-sm {
  font-size: 13px;
}

.dropdown-toggle::after {
  vertical-align: 0.15em;
}

.stamp {
  position: absolute;
  border: 7px solid;
  font-weight: bold;
  font-size: 40px;
  height: 4rem;
  line-height: 1;
  padding: 2px 8px;
  color: rgb(181, 181, 181);
  left: 50%;
  top: 36px;
  transform: translate(-50%) rotate(-15deg);
  text-transform: uppercase;
  pointer-events: none;
}

.btn-status,
.btn-status:hover {
  color: $colors-neutrals-black;
  cursor: default;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  background-color: $primary-background;
  padding: 0.5vh 2vw;
  margin-left: 2vw;
}

.invoice-navigate-left.disabled,
.invoice-navigate-right.disabled {
  opacity: 0.5;
  background: rgb(240, 240, 240);
  border: 1px;
}

/***** PRODUCTS MODULE STYLES *****/
/*Colors*/
.iconColor {
  color: $iconColor;
}
.grayMedium {
  color: $grayMedium;
}

.color-neutrals-800 {
  color: $colors-neutrals-n800;
}

.link-text {
  color: $colors-purple-600;
  cursor: pointer;
  text-decoration: none;
}

.entry-text-imported {
  font-weight: 400;
  font-size: 12px;
  color: $colors-purple-400;
  margin: 0 0 1rem -1rem;
  text-align: center;
}

.link-black {
  color: $colors-neutrals-ink;
}

.no-link {
  text-decoration: none;
  color: $body-color;
}

.btn.btn-group-custom {
  border: 1px solid $dark;
  border-radius: 3px;
}

.btn-active {
  background-color: rgba(82, 69, 154, 0.2) !important;
  color: $colors-neutrals-black !important;
  opacity: 1 !important;
}

.btn-inactive {
  background-color: $backgroundRedIcon !important;
  color: $colors-neutrals-black !important;
  opacity: 1 !important;
}

.btn-light {
  background-color: $colors-neutrals-white !important;
  color: $dark;
}

.btn-back {
  color: $grayMedium;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.gray-label {
  font-size: 12px;
  text-transform: uppercase;
  color: $grayMedium;
}

.right-col-45 {
  width: 45%;
  float: left;
}

.textarea {
  resize: none;
  border: 1px solid $border-input;
  border-radius: 4px;
  padding: 10px 15px 10px;
  min-height: 65px;
}
.textarea-resize {
  border: 1px solid $border-input;
  border-radius: 4px;
  padding: 10px 15px 10px;
  min-height: 4vh;
  max-height: 65vh;
  overflow-y: "auto";
}
.textarea-resize:focus {
  border-color: #b1abdd;
  box-shadow: 0 0 0 0.25rem rgba(99, 87, 187, 0.25);
  outline: 0;
}

// slider force styles
.MuiSlider-colorPrimary {
  color: $grayMedium !important;
}
.MuiSlider-thumb {
  color: $colors-neutrals-black;
  box-shadow: none !important;
}

// custom textarea with html
.ql-toolbar {
  border-radius: 5px 5px 0 0;
}
.ql-enabled.ql-container {
  border-radius: 0 0 5px 5px;
  height: 20vh;
}
.ql-container.ql-snow.ql-disabled {
  border: none;
  .ql-editor {
    padding: 0 1% 0 0;
    height: auto;
    min-height: 12vh;
  }
  // remove margins from html tags => remove extra spaces
  * {
    margin: 0;
  }
}
// remove margins from html tags => remove extra spaces
.ql-disabled * {
  margin: 0;
}

.slider-indicators {
  width: 105%;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 12px;
    position: relative;
  }
}

.advocacy-description {
  width: 100%;
  ul.list-item {
    padding-left: 10px;
    li {
      color: $grayMedium;
      list-style: none;
      position: relative;
      padding-left: 25px;

      &::before {
        content: " ";
        width: 3px;
        height: 3px;
        background-color: $grayMedium;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
  }
}

.block {
  width: 65%;
  border: 1px solid $table-border;
  background: $background-white;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #0000000a;

  &.block-sticky {
    width: 32%;
    height: 50%;
    position: sticky;
    float: right;
    top: 10%;
  }

  .block-header {
    padding: 4vh 2vw;
    border-bottom: 1px solid $table-border;
  }
  .block-header.block-header-with-background {
    background-color: $backgroundProfile;
  }

  .block-body {
    padding: 25px 30px;
  }

  .block-footer {
    padding: 25px 30px;
    border-top: 1px solid $table-border;
  }
}

.count-amount {
  color: $grayMedium;
  position: relative;
  font-size: 14px;
  top: -0.1vh;
  left: 1vw;
}

.required {
  color: $red;
  position: absolute;
  margin: -3px 0 0 4px;
}

.sticky-row {
  position: sticky;
  top: 60px;
  width: calc(100% + 20px);
  margin-left: -20px;
  z-index: 10;
  background: $background-white;
}

.detail-page.fixed {
  width: calc(100vw - 240px);
  margin-left: 240px;
  transition: width 0.4s, margin-left 0.4s;
}

.detail-page {
  position: relative;
  transition: width 0.4s, margin-left 0.4s;
  width: 100%;
  float: right;
  margin-left: 0;

  // small content
  @media (max-width: 1400px) {
    .content {
      width: 90% !important;
    }
  }

  .content {
    padding-bottom: 30px;
    min-height: 90vh;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;

    .button-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: $background-white;
      padding-top: 5px;
      .content-header-title {
        color: $colors-neutrals-black;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
      }
    }

    .content-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 3%;
      .content-header-title {
        color: $colors-neutrals-black;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
      }
      .content-header-right {
        display: flex;
        button {
          max-height: 6vh;
        }
      }
    }
  }

  &.collapsed {
    width: 95%;
    transition: width 1s;
  }
}

.pagination {
  padding-top: 2%;
  justify-content: center;
  .page-item {
    margin: 0 3px;
    min-width: 3.5vw;
    text-align: center;
    .page-link {
      border-radius: 4px;
      border: 1px solid $primary;
      color: $primary;
    }
  }
  .page-item.active {
    .page-link {
      border-radius: 4px;
      border: none;
      background-color: $primary-background;
      color: $colors-neutrals-black;
    }
  }
  .page-item.disabled {
    .page-link {
      border-radius: 4px;
      border: none;
      background-color: $gray-200;
      color: $grayMedium;
    }
  }
}

.button-container {
  display: flex;
  justify-content: center;
  width: 84%;
  margin: 12% auto;
  .btn {
    width: 100%;
    font-weight: 400;
  }
}

.sidemenu-elements {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 60;
  background-color: $colors-neutrals-n100;
  top: 75px;
  list-style: none;
  margin: -1rem 0 0 -215px;
  padding: 0;
  max-width: 240px;
  min-width: 240px;
  transition: margin 0.4s;
  height: 100vh;
  border-right: 1px solid $dark;

  .collapse-row {
    opacity: 0;
    transition: opacity 0.4s;
  }

  // classes to avoid expand/force collapse
  &.not-hover:hover {
    margin: -1rem 0 0 -215px;
  }
  &.forceCollapse:hover {
    margin: -1rem 0 0 -215px;
  }
  &:hover {
    margin: -1rem 0 0 0;

    .collapse-row {
      display: block;
      opacity: 1;
    }
    .collapse-button {
      top: 5px;
      left: 12px;
      background-color: $colors-neutrals-n100;
    }
  }

  &.fixed {
    margin: -1rem 0 0 0;

    .collapse-row {
      display: block;
      opacity: 1;
    }
    .collapse-button {
      top: 15px;
      left: 227px;
      transition: margin 0.4s;
      background-color: $colors-neutrals-n100;
      &:hover {
        background-color: $colors-purple-500;
        .collapse-button-icon {
          color: $colors-neutrals-white;
        }
      }
    }
    .collapse-button:hover::before {
      border-top: 1px solid $colors-purple-500;
    }
    .collapse-button:hover::after {
      border-right: 1px solid $colors-purple-500;
      border-bottom: 1px solid $colors-purple-500;
    }
  }
  li {
    &.hoverable {
      margin: 0 24px 2px 24px;
    }
    a {
      display: flex;
      font-size: 14px;
      color: $colors-neutrals-ink;
      width: 100%;
      height: 28px;
      padding: 10px 0 10px 38px;
      align-items: center;
      text-decoration: none;
      transition: color 0.4s, background-color 0.4s;
      &.multiline {
        height: 45px;
      }
      .link-report {
        margin: 0 0.5vw 0 0;
      }
    }
    &.active,
    &.active.hoverable:hover {
      a {
        border-radius: 4px;
        color: $colors-purple-400;
        background-color: $colors-neutrals-n200;
        border-left: 4px solid $colors-purple-500;
        padding-left: 34px;
        font-weight: 600;
      }
    }
    &.hoverable:hover {
      a {
        border-radius: 4px;
        background-color: $colors-neutrals-n200;
        border-left: 4px solid $colors-purple-500;
        padding-left: 34px;
      }
    }
  }
  // zone to hover and avoid to show left nav bar
  .collapse-button-container {
    top: 10px;
    left: 215px;
    width: 48px;
    height: 36px;
    position: absolute;
  }

  .collapse-button {
    position: absolute;
    top: 5px;
    left: 12px;
    border-radius: 25%;
    background-color: $colors-neutrals-n100;
    border: none;
    width: 24px;
    height: 24px;
    font-size: 20px;
    transition: width 0.5s, top 0.5s, left 0.5s;

    &:focus {
      outline: 0px;
    }
    .collapse-button-icon {
      cursor: pointer;
      margin: -11px 0 0 -5px;
      color: $colors-neutrals-n900;
    }
    &:hover {
      background-color: $colors-purple-500;
      .collapse-button-icon {
        color: $colors-neutrals-white;
      }
    }
  }
  /* SOLUTION FOR SIDE MENU ICON WITH PARTIAL BORDERS => commented line 1121 */
  .collapse-button::before,
  .collapse-button::after {
    position: absolute;
    content: "";
    width: 50%;
    height: 90%;
  }
  .collapse-button::before {
    right: 0;
    top: 0;
    border-top: 1px solid $dark;
    border-top-right-radius: 25%;
  }
  .collapse-button::after {
    right: 0;
    bottom: 0;
    border-right: 1px solid $dark;
    border-bottom: 1px solid $dark;
    border-bottom-right-radius: 25%;
  }
  .collapse-button:hover::before {
    border-top: 1px solid $colors-purple-500;
  }
  .collapse-button:hover::after {
    border-right: 1px solid $colors-purple-500;
    border-bottom: 1px solid $colors-purple-500;
  }
  /* END OF SOLUTION */

  .link-title {
    display: flex;
    padding-left: 24px;
    margin-bottom: -5px;
    margin-top: 0.5rem;

    .link-title-icon {
      width: 17px;
      height: 17px;
      color: $colors-neutrals-ink;
      margin: 2px 10px 0 0;
    }
    .link-title-text {
      color: $colors-neutrals-ink;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

.status-style {
  width: 90%;
  text-align: center;
  padding: 1vh 1vw;
  border-radius: 4px;
  &.green {
    background-color: $backgroundGreenIcon;
    border-left: 4px solid $greenIcon;
  }

  &.yellow {
    background-color: $backgroundYellowIcon;
    border-left: 4px solid $yellowIcon;
  }

  &.orange {
    background-color: $backgroundOrangeIcon;
    border-left: 4px solid $orangeIcon;
  }

  &.red {
    background-color: $backgroundRedIcon;
    border-left: 4px solid $redIcon;
  }

  &.blue {
    background-color: $backgroundBlueIcon;
    border-left: 4px solid $blueIcon;
  }
}

.status-gauge-description-container {
  width: 100%;
  margin: 2% 0 5% 0;
  border: 1px solid $table-border;
  border-radius: 9px;
  box-shadow: 0 2px 8px 0 $box-shadow;
  background-color: $background-white;

  .status-gauge {
    display: flex;
    justify-content: space-between;
    padding: 23px 15px 20px 24px;
    color: $darkMedium;
    &.green {
      background-color: $backgroundGreenIcon;
      border-left: 4px solid $greenIcon;
    }

    &.yellow {
      background-color: $backgroundYellowIcon;
      border-left: 4px solid $yellowIcon;
    }

    &.orange {
      background-color: $backgroundOrangeIcon;
      border-left: 4px solid $orangeIcon;
    }

    &.red {
      background-color: $backgroundRedIcon;
      border-left: 4px solid $redIcon;
    }

    &.blue {
      background-color: $backgroundBlueIcon;
      border-left: 4px solid $blueIcon;
    }

    .link-question-mark {
      justify-content: flex-end;
      margin-right: 2px;
    }

    .icon {
      color: $darkMedium;
      width: 20px;
      height: 20px;
    }
  }

  .status-description {
    padding: 2%;
    .status-author {
      color: $colors-neutrals-black;
      font-weight: 600;
    }
    .publish-date {
      color: $arrowGray;
    }
  }
  .status-comment {
    margin-top: 3%;
  }
}

.table-head-font {
  font-size: 11px;
  color: $grayMedium;
  text-align: center;
}

.vert-middle {
  vertical-align: middle;
}

.keyContactRow {
  border-bottom-width: 1px;
  td {
    border-bottom-width: 0;
    text-align: center;
    padding: 1rem 1rem;
  }
}

.disc-icon {
  color: $disc-icon;
  font-size: 18px;
  cursor: pointer;
}

.key-contact {
  .info-icon {
    color: $info-icon;
    font-size: 18px;
    margin-right: 1vw;
  }

  a {
    display: flex;
  }

  .key-contact-name-rol {
    margin-left: 1.1vw;
    .key-contact-name {
      text-align: start;
      font-weight: 600;
    }
    .key-contact-title {
      font-size: 12px;
      color: $help-text;
      text-align: start;
      font-weight: 500;
    }
  }
}

.key-contact-role-block {
  color: $help-text;
  background: $background-white;
  border: 1px solid $border-block;
  border-radius: 4px;
  display: inline-block;
  height: 22px;
  padding: 3px 12px 0;
  text-transform: uppercase;
  font-size: 12px;
  &.fs-small {
    width: fit-content;
    margin: auto;
  }
  &.trust {
    background-color: $trust;
    color: $colors-neutrals-white;
    text-transform: capitalize;
    border: none;
    font-weight: 500;
    line-height: 16px;
  }
  &.loyal {
    background-color: $loyal;
    color: $colors-itx-green-700;
    text-transform: capitalize;
    border: none;
    font-weight: 500;
    line-height: 16px;
  }
  &.advocate {
    background-color: $advocate;
    color: $colors-neutrals-white;
    text-transform: capitalize;
    border: none;
    font-weight: 500;
    line-height: 16px;
  }
  &.below {
    background-color: $below;
    color: $colors-neutrals-white;
    text-transform: capitalize;
    border: none;
    font-weight: 500;
    line-height: 16px;
  }
  &.driver {
    background-color: $driver;
    color: $colors-itx-green-700;
    text-transform: capitalize;
    border: none;
    font-weight: 500;
    line-height: 16px;
  }
}

.key-contact-profile-block {
  font-weight: bold;
  padding: 2px 12px;
  border-radius: 4px;
  color: $colors-neutrals-black;
  min-width: 4vw;
  justify-content: center;
  background-color: $backgroundProfile;
  height: 22px;
  line-height: 16px;
}

.iconTooltip {
  color: $iconColor;
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.trend-arrow {
  margin-left: 4px;
  vertical-align: text-top;
}

.criteria-list-block {
  width: 100%;
  margin: 2% 0;
  padding: 17px 40px;
  background: $criteria-achieved;
  cursor: pointer;

  .addressed-status {
    color: $primary;
  }

  .criteria-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 5px;
    color: $colors-neutrals-black;
  }

  .criteria-details {
    font-size: 12px;
    .criteria-bold {
      font-weight: 600;
      color: $criteria-bold;
    }
    .criteria-date {
      font-weight: 500;
      color: $help-text;
    }
  }

  &.selected {
    cursor: default;
  }
  &.unaddressed {
    &:not(.selected) {
      background: $criteria-unachieved;
    }
  }
}
.criteria-list-block:hover {
  &:not(.selected) {
    background: $criteria-hover;
  }
}

.ahead-of-schedule,
.on-track {
  color: $colors-neutrals-white;
  border: 1px solid $greenIcon;
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-block;
  font-size: 12px;
  background: $greenIcon;
}

.not-started {
  color: $arrowGray;
  border: 1px solid $table-border;
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-block;
  font-size: 12px;
  background: $colors-neutrals-white;
}

.cautionary {
  color: $colors-neutrals-white;
  border: 1px solid $driver;
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-block;
  font-size: 12px;
  background: $driver;
}

.critical {
  color: $colors-neutrals-white;
  border: 1px solid $below;
  border-radius: 4px;
  padding: 2px 8px;
  display: inline-block;
  font-size: 12px;
  background: $below;
}

.criteria-detail-text {
  margin: 18px 0 0;
  // to avoid margin en quill
  p {
    margin: 0;
  }
  ol,
  ul {
    margin: 0;
  }
}

// collapse animation
@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 0.5;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.no-data-yet {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 35% 0 15%;
  text-align: center;
  .image {
    width: 30%;
    margin: 5% auto;
  }
  .button-secondary {
    width: 30%;
    margin: 5% auto;
  }
}

.spotlight-disabled {
  border: 1px solid $table-border;
  background-color: $colors-neutrals-white;
  border-radius: 10px;
  text-align: center;
  padding: 4%;
  box-shadow: 0 2px 8px 0 $box-shadow;
  width: 80%;
  margin-top: 2%;
  span {
    font-size: 16px;
    color: $darkMedium;
    font-weight: 600;
  }

  p {
    margin-bottom: 2%;
  }

  .grey-button {
    border: 1px solid #d8dce6;
    border-radius: 4px;
    font-size: 14px;
    padding: 8px 16px;
    box-shadow: 0 1px 1px 0 #f3f3f4, inset 0 2px 0 0 $colors-neutrals-white;
    background-image: linear-gradient(to top, #f6f7f9, $colors-neutrals-white);
  }
}

.spotlight-report-summary-sort {
  box-shadow: 2px 2px 8px 0 $box-shadow;
  border: 1px solid $table-border;
  background-color: $colors-neutrals-white;
  padding: 18px 0px 0px 30px;
  margin-top: 30px;
  width: 100%;
  p {
    font-weight: 600;
    color: $filterColor;
    margin-bottom: 15px;
    display: inline-block;
  }

  .sort-status {
    display: inline-block;
    padding: 1px 20px;
    cursor: pointer;
    margin-right: 15px;
    &:hover:not(.disabled) {
      .sort-title {
        color: $colors-purple-600;
      }
      .sort-bubble {
        color: $colors-purple-600;
        &.All {
          background-color: $colors-purple-300;
        }
        &.NoMajorIssues {
          background-color: $greenIcon50;
        }
        &.Cautionary {
          background-color: $yellowIcon50;
        }
        &.Critical {
          background-color: $redIcon50;
        }
        &.OnHold {
          background-color: $blueIcon50;
        }
        &.InternalCritical {
          background-color: $orangeIcon50;
        }
      }
    }
    &.selected:not(.disabled) {
      border-bottom: 6px solid $colors-purple-600;
      .sort-title {
        font-weight: bolder;
        color: $colors-purple-600;
      }
      .sort-bubble {
        color: $colors-purple-600;
        &.All {
          background-color: $colors-purple-300;
        }
        &.NoMajorIssues {
          background-color: $greenIcon50;
        }
        &.Cautionary {
          background-color: $yellowIcon50;
        }
        &.Critical {
          background-color: $redIcon50;
        }
        &.OnHold {
          background-color: $blueIcon50;
        }
        &.InternalCritical {
          background-color: $orangeIcon50;
        }
      }
    }
    &.disabled .sort-title {
      color: $grayMedium;
      font-style: italic;
      font-weight: 100;
    }
    &.disabled:hover {
      border-bottom: none;
      cursor: default;
      .sort-title {
        color: $grayMedium;
        font-style: italic;
        font-weight: 100;
      }
      .sort-bubble {
        background-color: $table-border;
      }
    }
    .sort-title {
      color: $filterColor;
    }
    .sort-bubble {
      border-radius: 13px;
      padding: 3px 12px;
      margin-left: 11px;
      &.All {
        background-color: $colors-purple-200;
      }
      &.NoMajorIssues {
        background-color: $greenIcon30;
      }
      &.Cautionary {
        background-color: $yellowIcon30;
      }
      &.Critical {
        background-color: $redIcon30;
      }
      &.OnHold {
        background-color: $blueIcon30;
      }
      &.InternalCritical {
        background-color: $orangeIcon30;
      }
    }
  }
}

.product-link td {
  cursor: pointer;
}
.product-link:hover {
  box-shadow: 0px 1px 5px -1px $grayMedium !important;
  border-right-style: none !important;
  border-left-style: none !important;
}
.td-shadow-effect {
  background-color: transparent !important;
}

tr.product-link .iconsRow {
  color: $grayMedium !important;
  opacity: 0;
}

tr.product-link:hover .iconsRow {
  opacity: 1;
}

.iconsRow .purpleHover:hover {
  color: $primary;
}
.iconsRow .redHover:hover {
  color: $redIcon;
}

.main-title {
  color: $darkMedium;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.helper-title {
  color: $iconColor;
  font-size: 12px;
  font-weight: 100;
  margin: 0;
  position: relative;
  line-height: 15px;
}

.spotlight-report-summary-status-parent {
  &.spotlight-critical {
    background-color: $backgroundRedIcon !important;
  }
  &.spotlight-internal-critical {
    background-color: $backgroundOrangeIcon !important;
  }
  &.spotlight-cautionary {
    background-color: $backgroundYellowIcon !important;
  }
  &.spotlight-no-major-issues {
    background-color: $backgroundGreenIcon !important;
  }
  &.spotlight-on-hold {
    background-color: $backgroundBlueIcon !important;
  }
}

.spotlight-report-summary-status {
  display: block;
  padding: 7vh 2vw;
}

.icon {
  background-size: auto;
  width: 30px;
  height: 30px;
  margin: auto;
  &.spotlight-no-major-issues {
    background: url("./../styles/legacy/no-major-issues-green.svg") no-repeat;
    width: 35px;
    height: 34px;
  }
  &.spotlight-cautionary {
    background: url("./../styles/legacy/cautionary-yellow.svg") no-repeat;
  }
  &.spotlight-internal-critical {
    background: url("./../styles/legacy/internal-critical-orange.svg") no-repeat;
  }
  &.spotlight-critical {
    background: url("./../styles/legacy/critical-red.svg") no-repeat;
  }
  &.spotlight-on-hold {
    background: url("./../styles/legacy/on-hold-blue.svg") no-repeat;
    width: 35px;
    height: 34px;
  }
}

.spotlight-report-summary-value-block {
  margin: 0 auto;
  text-align: center;
}
.status-value {
  font-size: 12px;
  font-weight: 600;
  height: 26px;
  border-radius: 13px;
  background-color: $table-border;
  text-align: center;
  margin: 0 auto;
  color: $darkMedium;
  display: inline-block;
  padding: 4px 7px 0 7px;
  &.red {
    background-color: #f4b8ad;
  }
  &.yellow {
    background-color: #ffea9c;
  }
  &.none-red {
    background-color: $table-border;
  }
}

.spotlight-report-summary-budget-block {
  padding: 15% 0;
  text-align: center;
  p {
    margin-bottom: 0;
    color: $darkMedium;
  }
  span {
    color: $iconColor;
    font-size: 10px;
  }
}

.spotlight-red {
  background-color: #fbe9e6 !important;
}

.spotlight-report-comments-block {
  overflow: hidden;
  padding: 0px 15px;
  .comments {
    font-size: 12px;
    margin: 0;
  }
  .long-text {
    line-height: initial;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 0;
  }
  .long-text > * {
    margin: 0;
  }
}

.spotlight-report-summary-client-container {
  display: inline-block;
  padding-left: 15px;
  a {
    display: block;
    padding: 10px 0px;
    text-decoration: none;
  }
  .client-name {
    margin-bottom: 0px;
    color: $darkMedium;
  }
  .product-name {
    margin-bottom: 0px;
    font-size: 12px;
    color: $role-gray;
    position: relative;
    bottom: 4px;
  }
}

.spotlight-report-updated-block {
  padding: 0px 10px 0px 15px;
  cursor: pointer;
  text-align: center;
  p {
    font-size: 12px;
    margin: 0;
    line-height: initial;
  }
  .name {
    color: $arrowGray;
  }
  .long-text {
    line-height: initial;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &.two-lines {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
    &.one-line {
      line-clamp: 1;
      -webkit-line-clamp: 1;
    }
  }
}

// Telerik report styles
.trv-content.trv-document-map-splitter.k-widget.k-splitter.k-pane.k-scrollable {
  .trv-pages-area.k-widget.k-pane.k-scrollable.scrollable.interactive {
    width: 100% !important;
    height: 100% !important;
  }
}

// GLOBAL CALENDAR STYLES
.calendar-input {
  height: 36px;
}
[class^="Calendar_icon__"] {
  top: 9px;
}

// add this class to put calendar's modal above input
.calendar-up .react-calendar {
  margin-top: -22.5em;
}

// admin styles
.admin-title {
  text-align: center;
  margin: 2vh 0;
  font-weight: bold;
  font-size: 3em;
  color: #4a627e;
}
.admin-container {
  display: flex;
  margin: 3% auto 10%;
  width: 100%;
}
.left-column,
.right-column {
  display: flex;
  flex-direction: column;
  flex: 50%;
  margin-left: 5%;
}
.button-link {
  height: 6vh;
  line-height: 0px;
  margin: 2vh 4vw 0 0;
}
.btn-primary:disabled,
.btn-primary.disabled {
  color: $colors-neutrals-n700 !important;
  background-color: $colors-purple-200 !important;
  border: 1px solid $colors-purple-200 !important;
}

.button-secondary,
.button-secondary-danger {
  color: $primary;
  background-color: $colors-neutrals-white;
  border: 1px solid $primary;
}
.button-secondary:hover {
  color: $colors-neutrals-white;
  background-color: $primary;
  border: 1px solid $primary;
  cursor: pointer;
}
.button-secondary:disabled,
.button-secondary.disabled {
  color: $colors-purple-200 !important;
  border: 1px solid $colors-purple-200 !important;
}
.button-secondary-danger:hover {
  color: $colors-neutrals-white;
  background-color: $red-button;
  border: 1px solid $red-button;
  cursor: pointer;
}
.button-transparent {
  background-color: none !important;
  &-purple {
    color: $primary;
    border: 1px solid $primary;
  }
  &-red {
    color: $red-button;
    border: 1px solid $red-button;
  }
}

.button-transparent-purple:hover {
  color: $primary;
  border: 1px solid $primary;
  background-color: $colors-purple-300;
}
.button-transparent-red:hover {
  color: $red-button;
  border: 1px solid $red-button;
  background-color: $redIcon50;
}

.spinner-button {
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-eg0mwd-MuiSlider-thumb,
.css-1gv0vcd-MuiSlider-track {
  color: $colors-purple-600;
}

.close-label {
  color: $colors-purple-600;
  margin-right: 2vw;
  cursor: pointer;
}

/***** TIMES MODULE STYLES *****/

.times-header-input {
  width: 176px;
}
.arrow-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: $colors-neutrals-n800;
  margin: 6px 4px;

  &:hover {
    color: $colors-neutrals-white;
    background-color: $colors-purple-400;
    border-radius: $spacing-spacing-4;
  }
}
.arrow-icon-nohover {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: $colors-neutrals-n800;
  margin: 6px 4px;
}

.entry-hour-box-background {
  background-color: $colors-neutrals-n100;
  border: 1px solid $invoices-border;
}

.summary-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 16px;
  &-value {
    font-weight: 700;
  }
}
.summary-section-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: $colors-neutrals-ink;
}
.summary-section-number {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $colors-neutrals-ink;
}
.summary-section-bottom-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  color: $colors-neutrals-ink;
}

.view-button-secondary {
  min-width: 85px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid $colors-neutrals-n700;
  background-color: transparent;
  color: $colors-neutrals-n700;
  position: relative;

  &:hover {
    border: 1px solid $colors-neutrals-n700;
    color: $colors-neutrals-n900;
  }

  .view-icon {
    width: 24px;
    height: 24px;
  }

  &.active {
    border: 1px solid $colors-purple-600;
    background-color: $colors-purple-100;
    color: $colors-purple-600;
    z-index: 5;

    .view-icon {
      color: $colors-purple-600;
    }
  }
}
.table-date {
  color: $colors-neutrals-ink;
  font-size: 18px;
  font-weight: 600;
}
.right-side {
  width: 45%;
  // small content
  @media (max-width: 1400px) {
    width: 60% !important;
  }
}
.grey-text {
  color: $colors-neutrals-n800;
  font-size: 14px;
  font-weight: 400;
  margin-right: 1%;
  align-items: center;
}
.totals-text {
  color: $colors-neutrals-ink;
  font-weight: 600;
  background-color: $colors-neutrals-n200;
  border: 1px solid $colors-neutrals-n500;
  border-radius: 4px;
  width: 92px;
  text-align: center;
  align-items: center;
}

.total-hours-day {
  color: $colors-neutrals-ink !important;
  font-weight: 700;
}
.total-hours-subtask {
  color: $colors-neutrals-n800 !important;
}
.sum-totals-hours {
  font-weight: 700;
  color: $colors-neutrals-ink;
}

.table-td-white {
  background-color: $colors-neutrals-white !important;
}
.table-td {
  background-color: $colors-neutrals-n100 !important;
}
.table-today-th {
  background-color: $colors-purple-200 !important;
}
.table-today-td {
  background-color: $colors-purple-100 !important;
}
.table-holiday-td {
  background-color: $colors-green-100 !important;
}
.table-totals {
  background-color: $colors-neutrals-n200 !important;
}

.td-small-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
.td-task-link {
  font-weight: 500;
  line-height: 20px;
  color: $colors-purple-400;
  text-decoration: underline;
  cursor: pointer;
  min-width: fit-content;
}
.td-task-link-approval {
  font-weight: 500;
  line-height: 20px;
  color: $colors-purple-400;
  text-decoration: underline;
  cursor: pointer;
}
.td-approval {
  padding-right: 1.5rem;
  @media (max-width: 1500px) {
    padding-right: 0.75rem;
  }
}

.td-bold {
  font-weight: 600;
  line-height: 20px;
}
.td-text {
  font-weight: 400;
  line-height: 20px;
}

.status-width {
  width: 50%;
  margin: auto;
  @media (max-width: 1400px) {
    width: 100%;
    margin: 0;
  }
}
.td-status {
  border-radius: 4px;
  padding: 3px 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $colors-neutrals-n900;
  &.Pending {
    background-color: $colors-blue-50;
    border: 1px solid $colors-blue-200;
  }
  &.Approved {
    background-color: $colors-green-100;
    border: 1px solid $colors-green-200;
  }
  &.Locked,
  &.Complete,
  &.Resolved,
  &.Closed,
  &.Inactive,
  &.Canceled {
    background-color: $colors-neutrals-white;
    border: 1px solid $colors-neutrals-n800;
  }
  &.Unassigned {
    color: $colors-red-500;
    background-color: $colors-neutrals-white;
    border: 1px solid $colors-red-500;
  }
}
.icon-action {
  width: 20px;
  height: 20px;
  color: $colors-purple-400;
  margin-right: 10px;
  // small content
  @media (max-width: 1400px) {
    margin-right: 6px;
  }
  cursor: pointer;
  &:hover {
    color: $colors-purple-600;
  }
  &.disabled {
    color: $colors-neutrals-n500;
    cursor: default;
  }
}

.action-icons-td {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
  text-align: start;
  // small content
  @media (max-width: 1500px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
    min-width: 75px;
  }
}

// inputNumberHours component styles
.MuiInputBase-input {
  height: 36px !important;
  padding: 0 10px !important;
  background-color: $background-white !important;
}
.MuiInputBase-input.Mui-disabled {
  background-color: $gray-200 !important;
}
.MuiOutlinedInput-root fieldset {
  border: 1px solid $select-border-color !important;
}
.MuiOutlinedInput-root.Mui-focused fieldset {
  border: 1px solid $select-border-color !important;
  box-shadow: none !important;
}

.textarea-entry-box {
  border-radius: 4px;
  padding: 5px 15px;
  min-height: 4vh;
  max-height: 65vh;
  overflow-y: "auto";
  &:hover {
    box-shadow: 0 0 0 2px $select-border-hover !important;
    border-color: transparent !important;
  }
  &.disabled:hover {
    box-shadow: none !important;
    border-color: #7676764d !important;
  }
  &:focus {
    outline: none;
  }
}
.clear-button {
  color: $colors-purple-400;
  width: auto;
  margin: auto 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
.delete-button {
  color: $colors-red-400;
  width: auto;
  margin: auto 0 0 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

// menu of select, to avoid see list/sheet button over menu
[class^="-menu"],
[class$="-menu"],
[class*="-menu"] {
  z-index: 55 !important;
}

.task-row {
  border-left: 4px solid $colors-purple-300;
  border-radius: 4px;
  padding: 8px 30px;
}
@media (max-width: 1400px) {
  .task-row {
    padding: 8px 0 8px 10px;
  }
}

.modal-info-action {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $colors-neutrals-black;
}
.modal-info-action-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $colors-neutrals-n800;
  width: 90%;
}
.text-overflow {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 390px;
}

.time-entry-clickable {
  cursor: pointer;
  &:hover {
    background-color: $colors-purple-200 !important;
  }
}
.today-clickable {
  cursor: pointer;
  &:hover {
    background-color: $colors-purple-200 !important;
  }
}

.table-row-multiple-entries div {
  background-color: $colors-neutrals-n300 !important;
  font-weight: 400;

  .entry-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon-entry-action {
    width: 24px;
    height: 24px;
    color: $colors-neutrals-n900;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      color: $colors-neutrals-n800;
    }
    &.disabled {
      color: $colors-neutrals-n500;
      cursor: default;
    }
  }
}

.today-disabled {
  color: $colors-neutrals-n500;
  cursor: default;
}

.text-error {
  color: $colors-red-400;
  font-weight: 600;
}
.border-error {
  border: 1px solid $colors-red-400 !important;
  border-radius: 4px !important;
  border-color: transparent;
  box-shadow: 0 0 0 1px $colors-red-400 !important;
  // inputNumberHours component styles
  .MuiInputBase-input {
    border: 1px solid $colors-red-400 !important;
  }
}

.error-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0.25rem;
}
.error-icon {
  color: $colors-red-400;
  width: 20px;
  height: 20px;
  margin-top: -4px;
  &.big {
    width: 24px !important;
    height: 24px !important;
  }
}

.card-error {
  min-height: 56px;
  border-radius: 4px;
  background-color: $colors-red-50;
  border: 1px solid $colors-red-100;

  &.multiple-lines {
    height: 65px;
  }

  &.with-border {
    border: 1px solid $colors-red-100;
    border-radius: 4px;
  }

  .red-text {
    color: $colors-red-400;
    font-weight: 700;
  }
  .black-text {
    color: $colors-neutrals-ink;
  }
}

.warning-message {
  font-size: 14px;
  font-weight: 400;
  color: $colors-orange-600;
  line-height: 20px;
  margin: 0.25rem;
}
.warning-icon {
  width: 20px;
  height: 20px;
  margin-top: -4px;
  &.big {
    width: 24px;
    height: 24px;
  }
}
.card-warning {
  height: 56px;
  border-radius: 4px;
  background-color: $colors-orange-50;
  border: 1px solid $colors-orange-200;

  .orange-text {
    font-weight: 700;
  }
  .black-text {
    color: $colors-neutrals-ink;
  }
}

.success-message {
  font-size: 14px;
  font-weight: 400;
  color: $colors-green-700;
  line-height: 20px;
  margin: 0.25rem;
}
.success-icon {
  width: 20px;
  height: 20px;
  margin-top: -4px;
  &.big {
    width: 24px;
    height: 24px;
  }
}

.card-success {
  height: 56px;
  border-radius: 4px;
  background-color: $colors-green-50;
  border: 1px solid $colors-green-200;

  .green-text {
    font-weight: 700;
  }
  .black-text {
    color: $colors-neutrals-ink;
  }
}

.border-left {
  border-left: 1px solid #dee2e6;
}
.border-right {
  border-right: 1px solid #dee2e6;
}

.card-meal-break {
  background-color: $colors-blue-50;
  border-radius: 5px;
  border: 1px solid $colors-blue-100;
  .blue-text {
    color: $colors-blue-600;
    font-weight: bold;
    width: 15%;
  }
  .meal-break-box-icon {
    width: 36px;
    height: 36px;
    margin: 0.5rem 0 0 1rem;
  }
}

.meal-break-icon {
  color: $colors-neutrals-black;
  width: 18px;
  height: 18px;
  position: relative;
  z-index: 20;
  top: -28px;
  left: 10px;
}
.has-clock-icon > [class$="-control"] > div > [class$="-singleValue"] {
  padding-left: 25px;
}

.text-wrap {
  max-width: 400px;
  word-wrap: break-word;
}

.icon-time-reports {
  width: 24px;
  height: 24px;
  color: $colors-purple-400;
}
.card-title-time-reports {
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: $colors-neutrals-ink;
}
.card-width-reports {
  width: 330px;
  min-width: 330px;
}
.right-column-reports {
  width: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sheet-view-subtask {
  height: 44px;
  padding: 0 0 0 2rem;
  max-width: 30vw;
  .td-text {
    font-weight: 400;
    line-height: 44px;
    border-left: 3px solid $colors-purple-200;
    padding-left: 1rem;
    padding-right: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.red-number {
  color: $colors-red-400;
  font-weight: 500;
}
.green-number {
  color: $colors-green-500;
  font-weight: 500;
}

.project-task {
  height: 64px;
  max-width: 30vw;
}
.td-name-text {
  font-weight: 700;
  margin-left: 0.25rem;
  padding-right: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

// home page
.home-card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $colors-neutrals-ink;
}
.card-hours {
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: $colors-neutrals-ink;
  .totals {
    font-weight: 400;
    font-size: 20px;
    line-height: 39px;
  }
}
.card-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 39px;
  color: $colors-neutrals-n800;
}

// who is out report
.title-table {
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.cell-meal-break-error {
  background-color: $colors-red-50 !important;
  padding: 0px !important;
  border-left: 1px solid #dee2e6;
  .border-meal-break {
    border-left: 4px solid $colors-red-500;
    padding-left: 20px;
    div {
      line-height: 55px;
    }
  }
}

.cell-meal-break {
  padding: 0px !important;
  border-left: 1px solid #dee2e6;
  .border-meal-break {
    padding-left: 24px;
    div {
      line-height: 55px;
    }
  }
}
